import { isPaidMember, isUniquePage } from '@/utils/manage';

const CREATED_AT = ['created_start_at', 'created_end_at']; // 创建时间
const JOINED_AT = ['joined_start_at', 'joined_end_at']; // 入会时间
const UPDATED_AT = ['updated_start_at', 'updated_end_at']; // 更新时间
const SELL_AT = ['sell_start_at', 'sell_end_at']; // 售卖时间
const PAID_AT = ['paid_start_at', 'paid_end_at']; // 付款时间
const ORDER_AT = ['order_created_start_at', 'order_created_end_at']; // 下单时间
const LAST_UPDATED_AT = ['start_last_updated_at', 'end_last_updated_at']; // 最后更新时间

function initList() {
    const isUniqueTitle = isUniquePage() ? '集团' : '商场';
    // 会员列表
    let _member = [
        {
            label: `${isUniqueTitle}入会时间`,
            keys: CREATED_AT,
        },
    ];
    if (isPaidMember()) {
        _member.push({
            label: `到期时间`,
            keys: ['premium_start_at', 'premium_end_at'],
        });
    }

    return {
        // 会员列表
        member: _member,
        // 积分记录
        integral: [
            {
                label: '积分变动时间',
                keys: CREATED_AT,
                hashKeys: ['start', 'end'], // 映射url参数
            },
        ],
        // 消费记录
        consume: [
            {
                label: '消费时间',
                keys: PAID_AT,
            },
        ],
        // 会员分群
        memberClustering: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
            {
                label: '更新时间',
                keys: UPDATED_AT,
            },
        ],
        // 黑名单
        blackList: [
            {
                label: `${isUniqueTitle}入会时间`,
                keys: JOINED_AT,
            },
            {
                label: '移入黑名单时间',
                keys: CREATED_AT,
            },
        ],
        // 注销会员
        cancelMember: [
            {
                label: `${isUniqueTitle}入会时间`,
                keys: JOINED_AT,
            },
            {
                label: '注销时间',
                keys: CREATED_AT,
            },
        ],
        // 优惠券 / 停车券
        coupon: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
            {
                label: '发放时间',
                keys: ['sended_start_at', 'sended_end_at'],
            },
        ],
        // 第三方优惠券
        thirdPartyCoupons: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
        ],
        // 微信代金券
        wxCoupon: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
            {
                label: '可用开始时间',
                keys: ['available_start_at', 'available_end_at'],
            },
        ],
        // 云闪付有价券
        ysfCoupon: [
            {
                label: '可用时间',
                keys: ['valid_start_at', 'valid_end_at'],
            },
            {
                label: '售卖时间',
                keys: SELL_AT,
            },
        ],
        // 闪购 / 拼团
        goodsActivity: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
            {
                label: '售卖时间',
                keys: SELL_AT,
            },
        ],
        // 积分商品
        itemList: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
            {
                label: '兑换时间',
                keys: SELL_AT,
            },
        ],
        // 兑换订单
        orderList: [
            {
                label: '核销时间',
                keys: ['verified_start_at', 'verified_end_at'],
            },
            {
                label: '兑换时间',
                keys: PAID_AT,
            },
        ],
        // 营销活动
        market: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
            {
                label: '实际结束时间',
                keys: ['finished_start_at', 'finished_end_at'],
            },
            {
                label: '活动有效期',
                keys: ['campaign_start_at', 'campaign_end_at'],
            },
        ],
        // 互动营销宝
        interact: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
            {
                label: '活动有效期',
                keys: ['campaign_start_at', 'campaign_end_at'],
            },
        ],
        // 报名活动 / 报名活动专区
        activityRegistration: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
            {
                label: '活动时间',
                keys: ['campaign_start_at', 'campaign_end_at'],
            },
            {
                label: '报名时间',
                keys: ['signup_start_at', 'signup_end_at'],
            },
        ],
        // 种草笔记
        recommend: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
        ],
        // 定向发卷
        sendCoupon: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
            {
                label: '发券时间',
                keys: ['send_start_at', 'send_end_at'],
            },
        ],
        // 小程序直播
        liveTelecast: [
            {
                label: '计划时间',
                keys: ['live_start_at', 'live_end_at'],
            },
        ],
        // 线上商城订单
        groupOrder: [
            {
                label: '下单时间',
                keys: CREATED_AT,
            },
            {
                label: '付款时间',
                keys: PAID_AT,
            },
            {
                label: '核销时间',
                keys: ['verified_start_at', 'verified_end_at'],
            },
            {
                label: '发货时间',
                keys: ['shipped_start_at', 'shipped_end_at'],
            },
            {
                label: '确认收货时间',
                keys: ['confirmed_start_at', 'confirmed_end_at'],
            },
        ],
        // 线上商城订单
        groupOrderRefund: [
            {
                label: '发起退款时间',
                keys: ['start_at', 'end_at'],
            },
        ],
        // 小程序收银订单
        cashOrder: [
            {
                label: '付款时间',
                keys: PAID_AT,
            },
        ],
        // 小程序收银退款订单
        cashOrderRefund: [
            {
                label: '发起退款时间',
                keys: ['start_at', 'end_at'],
            },
        ],
        // 传统分账账单
        goodsAccount: [
            {
                label: '下单时间',
                keys: ORDER_AT,
            },
            {
                label: '预计分账时间',
                keys: ['profitshared_start_at', 'profitshared_end_at'],
            },
        ],
        // 进阶分账账单
        splitAccount: [
            {
                label: '下单时间',
                keys: ORDER_AT,
            },
            {
                label: '预计分账时间',
                keys: ['profitshared_start_at', 'profitshared_end_at'],
            },
        ],
        // 传统分账商场券账单
        mallGoodsAccount: [
            {
                label: '下单时间',
                keys: ORDER_AT,
            },
        ],
        // 传统分账运费账单
        fareAccount: [
            {
                label: '下单时间',
                keys: ORDER_AT,
            },
        ],
        // 传统分账运费账单
        capitalFlowTradition: [
            {
                label: '资金流水创建时间',
                keys: CREATED_AT,
            },
        ],
        // 进阶分账商场券账单
        mallSplitAccount: [
            {
                label: '下单时间',
                keys: ORDER_AT,
            },
        ],
        // 进阶分账资金流水
        capitalFlowMerchant: [
            {
                label: '资金流水创建时间',
                keys: CREATED_AT,
            },
        ],
        // 下载中心
        downloadCenter: [
            {
                label: '创建时间',
                keys: CREATED_AT,
            },
        ],
        // 会员反馈
        feedback: [
            {
                label: '反馈时间',
                keys: CREATED_AT,
            },
            {
                label: '处理时间',
                keys: ['reply_start_at', 'reply_end_at'],
            },
        ],
        // 问卷调查
        questionnaire: [
            {
                label: '结束时间',
                keys: ['end_start_at', 'end_end_at'],
            },
            {
                label: '实际结束时间',
                keys: ['finished_start_at', 'finished_end_at'],
            },
            {
                label: '发布时间',
                keys: ['start_start_at', 'start_end_at'],
            },
        ],
        // 小票审核
        ticketVerify: [
            {
                label: '消费时间',
                keys: PAID_AT,
            },
            {
                label: '提交审核时间',
                keys: CREATED_AT,
            },
            {
                label: '审核时间',
                keys: UPDATED_AT,
            },
        ],
        // 停车报表
        parkForms: [
            {
                label: '缴费时间',
                keys: PAID_AT,
            },
            {
                label: '入场时间',
                keys: ['entered_start_at', 'entered_end_at'],
            },
            {
                label: '出场时间',
                keys: ['exited_start_at', 'exited_end_at'],
            },
        ],
        // 闪购商品货架
        shelfManage: [
            {
                label: '售卖时间',
                keys: SELL_AT,
            },
        ],
        // 拼团商品货架
        groupManage: [
            {
                label: '活动时间',
                keys: SELL_AT,
            },
        ],
        // 有价券券架
        valuableManagement: [
            {
                label: '售卖时间',
                keys: SELL_AT,
            },
        ],
        // 免费券券架
        cardManage: [
            {
                label: '上架时间',
                keys: ['published_start_at', 'published_end_at'],
            },
        ],
        // 积分商品货架
        pointManage: [
            {
                label: '兑换时间',
                keys: SELL_AT,
            },
        ],
        // 积分商品货架
        OcrTicketVerify: [
            {
                label: '最后修改时间',
                keys: LAST_UPDATED_AT,
            },
        ],
        // 付费会员购买记录
        purchase: [
            {
                label: '支付/兑换时间',
                keys: ['paid_start_at', 'paid_end_at'],
            },
        ],
        // 渠道二维码
        channels: [
            {
                label: '创建时间',
                keys: ['created_start_at', 'created_end_at'],
            },
        ],
        // 华联兑换码-兑换记录
        exchangeRecord: [
            {
                label: '兑换时间',
                keys: ['exchanged_start_at', 'exchanged_end_at'],
            },
        ],
        // 华联兑换码-作废记录
        cancellationRecord: [
            {
                label: '作废时间',
                keys: ['invalid_start_at', 'invalid_end_at'],
            },
        ],
        // 优惠券报表
        couponReport: [
            {
                label: '领取时间',
                keys: CREATED_AT,
            },
            {
                label: '使用时间',
                keys: ['verified_start_at', 'verified_end_at'],
            },
        ],
        // 有价券报表
        voucherReport: [
            {
                label: '购买时间',
                keys: CREATED_AT,
            },
            {
                label: '使用时间',
                keys: ['verified_start_at', 'verified_end_at'],
            },
        ],
        // 兑换码管理
        redeemManage: [
            {
                label: '创建时间',
                keys: ['created_start_at', 'created_end_at'],
            },
        ],
        // 手动标签
        manualTag: [
            {
                label: '创建时间',
                keys: ['created_start_at', 'created_end_at']
            },
            {
                label: '最近编辑时间',
                keys: ['last_updated_start_at', 'last_updated_end_at']
            }
        ],
        // 点评券
        commentsCoupons: [
            {
                label: '创建时间',
                keys: ['created_start_at', 'created_end_at']
            },
            {
                label: '编辑时间',
                keys: ['last_updated_start_at', 'last_updated_end_at']
            }
        ],
        // 点评券-核销记录
        writeOffRecords: [
            {
                label: '核销记录',
                keys: ['created_start_at', 'created_end_at']
            },
        ]
    }
}

export const filterTimeList = (type) => {
    let list = initList();
    return list[type];
};

export const initFilterTimeKey = (type, date = []) => {
    const allKeysDict = allFilterTimeKeys(type);
    const params = allKeysDict[type];
    if (date.length) {
        const filters = filterTimeList(type);
        for (let row of filters) {
            for (let i = 0; i < row.keys.length; i++) {
                let key = row.keys[i];
                params[key] = date[i];
            }
        }
    }
    return params;
};

export const getFilterTimeDefaultKeys = (type) => {
    const filters = filterTimeList(type);
    return filters[0].keys;
};

export const allFilterTimeKeys = () => {
    const list = initList();
    let allParams = {};
    for (let key in list) {
        allParams[key] = {};
        for (let row of list[key]) {
            for (let i = 0; i < row.keys.length; i++) {
                let child = row.keys[i];
                allParams[key][child] = '';
            }
        }
    }
    return allParams;
};
