const parent = 'member';

export default [
    {
        path: "/member",// 会员列表
        name: "member",
        component: () => import('@/components/Member/List'),
    },
    {
        path: "/membermanage",// 会员列表 详情
        name: "membermanage",
        meta: { parent, name: 'parent' },
        component: () => import('@/components/Member/MemberManage'),
    },
    {
        path: "/memberManageGroup",// 会员列表 详情
        name: "memberManageGroup",
        meta: { parent, name: 'parent' },
        component: () => import('@/components/Member/MemberManageGroup.vue'),
    },
    // 打标签
    {
        path: "/memberTagging/:id",
        name: "MemberTagging",
        meta: { parent, name: "parent", title: '打标签' },
        component: () => import('@/views/MemberTagging/index'),
    },
    {
        path: "/memberTaggingDetail/:id",
        name: "MemberTaggingDetail",
        meta: { parent, name: "parent", title: '打标签详情' },
        component: () => import('@/views/MemberTagging/Detail/index'),
    },
    {
        path: "/membertag",
        name: "membertag",
        component: () => import('@/components/Member/MemberTag'),
    },
    {
        path: "/membertaglist",
        name: "membertaglist",
        meta: { parent: 'membertag', name: 'parent' },
        component: () => import('@/components/Member/List'),
    },
    // 会员分群
    {
        path: "/memberclustering",
        name: "memberclustering",
        meta: { parent, name: 'name' },
        component: () => import('@/components/PointMall/MemberClustering'),
    },
    // 新增会员分群
    {
        path: "/memberclusteringadd",
        name: "memberclusteringadd",
        meta: { parent, name: 'memberclustering' },
        component: () => import('@/components/PointMall/MemberClusteringAdd'),
    },
    // 查看会员分群
    {
        path: "/memberclusteringdetail",
        name: "memberclusteringdetail",
        meta: { parent, name: 'memberclustering' },
        component: () => import('@/components/PointMall/MemberClusteringDetail'),
    },
    // 黑名单
    {
        path: "/blackList",
        name: "blackList",
        meta: { parent, name: "name", title: '黑名单列表' },
        component: () => import('@/views/BlackList'),
    },
    // 手动标签列表
    {
        path: "/memberManualTag",
        name: "MemberManualTag",
        meta: { parent: 'MemberTagManage', name: "MemberManualTag", title: '手动标签', type: 1 },
        component: () => import('@/views/MemberTagManage/List/index.vue'),
    },
    // 手动标签--新增、编辑
    {
        path: "/memberManualTagEdit",
        name: "MemberManualTagEdit",
        meta: { parent: 'MemberTagManage', name: "MemberManualTag", title: '手动标签' },
        component: () => import('@/views/MemberTagManage/ManualTag/Edit'),
    },
    // 自动标签列表
    {
        path: "/memberAutoTag",
        name: "MemberAutoTag",
        meta: { parent: 'MemberTagManage', name: "MemberAutoTag", title: '自动标签', type: 2 },
        component: () => import('@/views/MemberTagManage/List/index.vue'),
    },
    // 自动标签--新增、编辑
    {
        path: "/memberAutoTagEdit",
        name: "MemberAutoTagEdit",
        meta: { parent: 'MemberTagManage', name: "MemberAutoTag", title: '自动动标签' },
        component: () => import('@/views/MemberTagManage/AutoTag/Edit'),
    },
    // 会员标签--标签详情
    {
        path: "/TagGroupDetail",
        name: "TagGroupDetail",
        meta: { parent, name: "name", title: '' },
        component: () => import('@/views/MemberTagManage/Detail'),
    },
];