const parent = 'marketrules';
const _name = { parent, name: 'name' };

const _namePaid = { parent, name: 'paidManagement' };

export default [
    // 注册与个人信息
    {
        path: '/viewregisterconfig',
        name: 'viewregisterconfig',
        meta: _name,
        component: () => import('@/views/registerConfig'),
    },
    // 积分规则
    {
        path: '/pointset',
        name: 'pointset',
        meta: _name,
        component: () => import('@/components/PointMall/PointSet'),
    },
    // 会员等级设置
    {
        path: '/memberLevel',
        name: 'memberLevel',
        meta: _name,
        component: () => import('@/components/PointMall/MemberLevel'),
    },
    // 会员等级礼配置
    {
        path: '/configureLevelGift',
        name: 'ConfigureLevelGift',
        meta: { parent, name: 'memberLevel' },
        component: () => import('@/views/MemberLevel/ConfigureLevelGift'),
    },
    // memberLevelupdate
    // 会员等级更新
    {
        path: '/memberLevelupdate',
        name: 'memberLevelupdate',
        meta: { parent, name: 'memberLevel' },
        component: () => import('@/components/PointMall/memberLevelupdate'),
    },
    // 签到设置
    {
        path: '/memberSign',
        name: 'memberSign',
        meta: _name,
        component: () => import('@/components/PointMall/MemberSign'),
    },
    // 付费会员管理
    {
        path: '/paidManagement',
        name: 'paidManagement',
        meta: _name,
        component: () => import('@/views/PaidManagement'),
    },
    // 付费会员 落地页配置
    {
        path: '/landingPageEdit',
        name: 'LandingPageEdit',
        meta: _namePaid,
        component: () => import('@/views/PageDragSetting/landingPage/edit'),
    },
    // 付费会员 落地页配置 详情
    {
        path: '/landingPageDetail',
        name: 'LandingPageDetail',
        meta: _namePaid,
        component: () => import('@/views/PageDragSetting/landingPage/detail'),
    },
    // 付费会员卡面设置
    {
        path: '/paidSettingEdit',
        name: 'PaidSettingEdit',
        meta: _namePaid,
        component: () => import('@/views/PaidManagement/PaidSetting/edit'),
    },
    // 付费会员卡面设置 详情
    {
        path: '/paidSettingDetail',
        name: 'PaidSettingDetail',
        meta: _namePaid,
        component: () => import('@/views/PaidManagement/PaidSetting/detail'),
    },
    // 售卖配置 详情
    {
        path: '/sellingSettingDetail',
        name: 'SellingSettingDetail',
        meta: _namePaid,
        component: () => import('@/views/PaidManagement/SellingSetting/detail'),
    },
    // 售卖配置 编辑
    {
        path: '/sellingSettingEdit',
        name: 'SellingSettingEdit',
        meta: _namePaid,
        component: () => import('@/views/PaidManagement/SellingSetting/detail'),
    },
    // 付费会员权益配置 详情
    {
        path: '/paidRightsSettingDetail',
        name: 'PaidRightsSettingDetail',
        meta: _namePaid,
        component: () => import('@/views/PaidManagement/PaidRightsSetting/detail'),
    },
    // 付费会员权益配置
    {
        path: '/paidRightsSettingEdit',
        name: 'PaidRightsSettingEdit',
        meta: _namePaid,
        component: () => import('@/views/PaidManagement/PaidRightsSetting/edit'),
    },
    // 付费会员协议
    {
        path: '/paidAgreement',
        name: 'paidAgreement',
        meta: _namePaid,
        component: () => import('@/views/PaidManagement/PaidAgreement'),
    },
    // 付费会员购买记录
    {
        path: '/paidPurchaseHistory',
        name: 'paidPurchaseHistory',
        meta: _namePaid,
        component: () => import('@/views/PaidManagement/PurchaseHistory'),
    },
];
